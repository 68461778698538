import React, { useState } from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import {
  Form,
  PrimaryButton,
  FieldCurrencyInput,
  Icons,
  FieldTextInput,
} from '../../../components';
import appSettings from '../../../config/settings';
import { getPriceValidators } from '../../EditListingPage/EditListingWizard/EditListingPricingAndStockPanel/EditListingPricingAndStockForm';
import { BASE_CURRENCY, BASE_CURRENCY_SYMBOL, LISTING_MIN_PRICE } from '../../../util/enums';

import css from './Offers.module.css';
import { calculatePercentage } from '../../../util/genericHelpers';
import { required } from '../../../util/validators';

const OfferFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        intl,
        price,
        listingTitle,
        authorDisplayName,
        sendEnquiryError,
        invalid,
        inquiryInProgress,
        form,
        isGuest,
        isCounterOffer,
        confirmationModalParams,
        setShowAccountCreationForm,
      } = fieldRenderProps;

      const [showReviewDetails, setShowReviewDetails] = useState(false);

      const classes = classNames(rootClassName || css.root, className);

      const priceValidators = getPriceValidators(LISTING_MIN_PRICE, BASE_CURRENCY, intl);

      const matomoEvent = amount => {
        // Matomo
        if (typeof _paq !== 'undefined') {
          _paq.push(['trackEvent', 'Shopping', 'ShopMakeOffer', amount]);
        }
      };

      const customHandleSubmit = async event => {
        const values = form.getState().values;
        const amount = values.price.amount / 100;
        matomoEvent(amount);
        handleSubmit(event);
      };

      const renderOfferInputs = (
        <>
          {isGuest ? (
            <div className={css.email}>
              <FieldTextInput
                type="text"
                id="email"
                name="email"
                className={css.input}
                disabled={showReviewDetails}
                label={intl.formatMessage({ id: 'OfferForm.emailLabel' })}
                placeholder={intl.formatMessage({ id: 'OfferForm.emailPlaceholderMessage' })}
                validate={required(intl.formatMessage({ id: 'OfferForm.emailRequiredMessage' }))}
              />
            </div>
          ) : null}
          <FieldCurrencyInput
            id="price"
            name="price"
            label={intl.formatMessage({ id: 'OfferForm.offerLabel' })}
            className={css.input}
            disabled={showReviewDetails}
            placeholder={`${BASE_CURRENCY_SYMBOL}${calculatePercentage(price?.amount / 100, 90)}`}
            currencyConfig={appSettings.getCurrencyFormatting(BASE_CURRENCY)}
            validate={priceValidators}
          />
        </>
      );

      const renderOfferButtons = (
        <>
          {!showReviewDetails && isGuest ? (
            <PrimaryButton
              className={css.submitBtn}
              type="button"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setShowReviewDetails(true);
              }}
            >
              <FormattedMessage id="OfferForm.continueText" />
            </PrimaryButton>
          ) : (
            <PrimaryButton
              className={css.submitBtn}
              type="submit"
              inProgress={inquiryInProgress}
              disabled={invalid}
            >
              <FormattedMessage id="OfferForm.submitButtonText" />
            </PrimaryButton>
          )}
        </>
      );

      const renderCreateAccountButton = (
        <PrimaryButton
          className={css.submitBtn}
          type="button"
          onClick={() => {
            setShowAccountCreationForm(true);
          }}
        >
          <FormattedMessage id="OfferForm.createAccount" />
        </PrimaryButton>
      );

      return (
        <Form
          className={classes}
          onSubmit={customHandleSubmit}
          enforcePagePreloadFor="OrderDetailsPage"
        >
          {!isCounterOffer && (
            <div className={css.offerFormHead}>
              <Icons name="offersIcon" />
              <h2 className={css.heading}>
                <FormattedMessage
                  id={confirmationModalParams ? 'OfferForm.guestHeading' : 'OfferForm.heading'}
                  values={{
                    listingTitle,
                    authorDisplayName,
                    offerPrice: confirmationModalParams?.offerPrice,
                  }}
                />
              </h2>
              <p className={css.description}>
                <FormattedMessage
                  id={
                    confirmationModalParams ? 'OfferForm.guestDescription' : 'OfferForm.description'
                  }
                  values={{
                    guestEmail: confirmationModalParams?.guestEmail,
                  }}
                />
              </p>
            </div>
          )}
          {!confirmationModalParams ? renderOfferInputs : null}
          <div className={css.actionBtns}>
            {sendEnquiryError ? (
              <p className={css.error}>
                <FormattedMessage id="OfferForm.sendEnquiryError" />
              </p>
            ) : null}
            {confirmationModalParams ? renderCreateAccountButton : null}
            {!confirmationModalParams ? renderOfferButtons : null}
          </div>
        </Form>
      );
    }}
  />
);

OfferFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendEnquiryError: null,
};

OfferFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendEnquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const OfferForm = compose(injectIntl)(OfferFormComponent);

OfferForm.displayName = 'OfferForm';

export default OfferForm;
